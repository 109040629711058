import ScrollPage from "@/components/ScrollPage";
import { PageHeader } from "@/components/Headers";
import { useEffect, useState } from 'react';
import { Profile } from '@/types/types'; // Assuming Profile type is defined in a types file
import useAxios from "@/hooks/useAxios";
import { useSelector } from "react-redux";
import { config } from "@/config";
import { Link } from 'react-router-dom';
import LoadingModal from "@/components/LoadingModal";

const ProfilePage = () => {
    const [profile, setProfile] = useState<Profile | null>(null)
    const axios = useAxios()
    const accountId = useSelector((state: any) => state.session.accountId)
    useEffect(() => {
        const fetchProfile = async () => {
            const option = {
                params: {
                    accountId
                }
            }
            const response = await axios.get(`${config.backendUrl}/payments/profiles`, option)
            console.log(response)
            setProfile(response.data)
        }
        fetchProfile()
    }, [])

    // Function to capitalize each word in a string
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    }

    // Runtime check for profile fields
    const safeGet = (value: any) => value !== undefined && value !== null ? value : '--';

    return (
        <ScrollPage>
            <LoadingModal loading={profile === null} />
            {profile &&
                <div className="min-h-screen w-full">
                    <div className="max-w-full w-full">
                        <div className="flex justify-between items-center mb-6">
                            <PageHeader>Business Profile</PageHeader>
                            <Link to="/profile/edit" className="inline-flex items-center justify-center bg-gradient-to-r from-purple-500 to-blue-500 text-white font-semibold py-2 px-8 rounded-full shadow-xl hover:from-purple-600 hover:to-blue-600 transform hover:scale-105 transition-all duration-300 ease-in-out">Edit Profile</Link>
                        </div>
                        <div className="mb-6">
                            <h2 className="text-lg font-medium border-b pb-2 mb-4">Business Details</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="font-medium">Business Name:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.businessName)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Doing Business As:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.doingBusinessAs)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Business Type:</label>
                                    <p className="text-sm text-gray-700">{capitalizeWords(safeGet(profile?.businessDetails?.businessType))}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Website:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.website)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Email:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.email)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Phone:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.phone)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">MCC:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.mcc)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">EIN:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.ein)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Address:</label>
                                    <p className="text-sm text-gray-700">
                                        {safeGet(profile?.businessDetails?.addressLine1)}, {profile?.businessDetails?.addressLine2 ? `${profile.businessDetails.addressLine2}, ` : ''}{safeGet(profile?.businessDetails?.city)}, {safeGet(profile?.businessDetails?.state)}, {safeGet(profile?.businessDetails?.zipcode)}, {profile?.businessDetails?.country ? capitalizeWords(profile.businessDetails.country) : 'N/A'}
                                    </p>
                                </div>
                                <div>
                                    <label className="font-medium">Business Description:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.businessDetails?.businessDescription)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-6">
                            <h2 className="text-lg font-medium border-b pb-2 mb-4">Transaction Details</h2>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div>
                                    <label className="font-medium">Average Monthly Volume:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.transactionDetails?.averageMonthlyVolume)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Maximum Transaction Amount:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.transactionDetails?.maximumTransactionAmount)}</p>
                                </div>
                                <div>
                                    <label className="font-medium">Average Transaction Amount:</label>
                                    <p className="text-sm text-gray-700">{safeGet(profile?.transactionDetails?.averageTransactionAmount)}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-lg font-medium border-b pb-2 mb-4">Representatives</h2>
                            {profile.representatives?.map((rep, index) => (
                                <div key={index} className="mb-4">
                                    <h3 className="text-md font-medium mb-2">
                                        {safeGet(rep?.firstName)} {safeGet(rep?.lastName)}
                                    </h3>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <label className="font-medium">Email:</label>
                                            <p className="text-sm text-gray-700">{safeGet(rep?.email)}</p>
                                        </div>
                                        <div>
                                            <label className="font-medium">Phone:</label>
                                            <p className="text-sm text-gray-700">{safeGet(rep?.phone)}</p>
                                        </div>
                                        <div>
                                            <label className="font-medium">Date of Birth:</label>
                                            <p className="text-sm text-gray-700">{safeGet(rep?.dob)}</p>
                                        </div>
                                        <div>
                                            <label className="font-medium">SSN:</label>
                                            <p className="text-sm text-gray-700">{safeGet(rep?.ssn)}</p>
                                        </div>
                                        <div>
                                            <label className="font-medium">Address:</label>
                                            <p className="text-sm text-gray-700">
                                                {safeGet(rep?.addressLine1)}, {rep?.addressLine2 ? `${rep.addressLine2}, ` : ''}{safeGet(rep?.city)}, {safeGet(rep?.state)}, {safeGet(rep?.zipcode)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </ScrollPage>
    );
};

export default ProfilePage;
