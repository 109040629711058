import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import axios from 'axios';
import { Mail, Lock } from 'lucide-react';
import { setSession } from '@/redux/actions/sessionAction';
import { config } from '@/config';

const LogInPage = () => {
    const [email, setEmail] = useState('sandbox@synswi.com');
    const [password, setPassword] = useState('1234');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async (e: any) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.backendUrl}/payments/login`, { email, password });
            const { token, accountId } = response.data;
            dispatch(setSession({ token, accountId }));
            navigate('/balances');
        } catch (error: any) {
            console.error('There was an error!', error);
            setErrorMessage(error.response?.data?.message || 'Fail, try again');
        }
    };

    return (
        <>
            <div className="min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-indigo-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 bg-white bg-opacity-10 p-10 rounded-xl backdrop-filter backdrop-blur-lg">
                    <div>
                        <h2 className="mt-6 text-center text-2xl font-extrabold text-white">
                            Login to your synswi account
                        </h2>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleLogin}>
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div className="flex items-center mb-4">
                                <Mail className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="your email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center mb-4">
                                <Lock className="h-5 w-5 text-indigo-500 mr-2" />
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                        

                            <div className="text-sm">
                                <a href="#" className="font-medium text-indigo-400 hover:text-indigo-300">
                                    Forget password?
                                </a>
                            </div>
                        </div>

                        {errorMessage && (
                            <div className="text-center text-red-500 text-sm">{errorMessage}</div>
                        )}

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default LogInPage;