import { TextInput } from "@/components/Inputs";
import { PageHeader } from "@/components/Headers";
import { useState } from "react";
import useAxios from "@/hooks/useAxios";
import { config } from "@/config";
import { useSelector } from "react-redux";
import PageButtonGroup from "./ProfileFormPageButtonGroup";
import { Selectinput } from "@/components/Inputs";
import { mccCodes } from "./MccCode";
export default function BusinessDetail({ businessDetails, setStep, setProfile }: any) {
    const axios = useAxios()
    const accountId = useSelector((state: any) => state.session.accountId)
    let t = {
        businessName: '', businessType: '', doingBusinessAs: '', website: '',
        phone: '', mcc: '', addressLine1: '', addressLine2: '', city: '', state: '',
        country: 'united states', zipcode: '', businessDescription: '', ein: '', ...businessDetails
    }
    const [formData, setFormData] = useState(t)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let oldBusinessDetails = formData
        let newBusinessDetails = { ...oldBusinessDetails, [name]: value }
        setFormData(newBusinessDetails)
    };

    const handleNext = async () => {
        await axios.post(`${config.backendUrl}/payments/profiles`, { accountId, businessDetails: formData })
        setProfile((prev: any) => { return { ...prev, businessDetails: formData } })
        setStep((prev: number) => prev + 1)
    }

    function generateSelectOptions(mccCodes: any) {
        let options = []
        options.push({value: '', label: 'Select your merchant category'})
        for (let item of mccCodes) {
            let option = {value: item.code, label: item.code + ": " + item.description}
            options.push(option)
        }
        return options
    }

    function generateCompanyTypeOptions() {
        let options = [
            {value: '', label: 'Select your business type'},
            {value: 'limited liability company', label: 'Limited Liability Company'},
            {value: 'corporation', label: 'Corporation'}
        ]

        return options
    }

    return (
        <div>
            <PageHeader>Business details</PageHeader>
            <div className="mb-4">
                <TextInput label="Business Name" name="businessName" value={formData.businessName} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Doing business as</label>
                <TextInput name="doingBusinessAs" value={formData.doingBusinessAs} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <Selectinput options={generateCompanyTypeOptions()} name="businessType" value={formData.businessType} label="Business type" onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Website" name="website" value={formData.website} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Phone Number" name="phone" value={formData.phone} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <Selectinput options={generateSelectOptions(mccCodes)} name="mcc" value={formData.mcc} label="MCC" onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Business Address" name="addressLine1" value={formData.addressLine1} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="City" name="city" value={formData.city} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="State" name="state" value={formData.state} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Zip Code" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput disabled label="Country" name="country" value={formData.country} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="EIN" name="ein" value={formData.ein} onChange={handleInputChange} />
            </div>
            <PageButtonGroup leftDisabled handleNext={handleNext} />
        </div>
    )
}