import { PageHeader } from "@/components/Headers";
import ScrollPage from "@/components/ScrollPage";

export default function Disputes() {
    return (
        <ScrollPage>
            <PageHeader>
                Disputes
            </PageHeader>
        </ScrollPage>
    )
}