import { Loader2 } from 'lucide-react';

export default function LoadingModal({ loading }: { loading: boolean }) {
    return (
        <>
            {loading ?
                <div className={`absolute inset-0 flex justify-center items-center bg-gray-50/40 `}>
                    <Loader2 className="h-8 w-8 animate-spin" />
                </div>
                : null
            }
        </>
    )
}