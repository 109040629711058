// this is the left panel and right content layout
import { Outlet } from "react-router-dom"
import Navbar from "./Navbar"
export default function Layout() {
    return (
        <div className="flex flex-col lg:flex-row h-full">
            <div>
                <Navbar />
            </div>
            <div className="grow min-w-0 min-h-0"> {/* This is giving the flex item a referenced width */}
                <Outlet />
            </div>
        </div>
    )
}