import ReactDOM from 'react-dom/client'
import './index.css'
import store from './redux/store';
import { Provider } from 'react-redux';
import Routes from './Routes.tsx';
// import NotificationContainer from './notificationContainer.jsx';
ReactDOM.createRoot(document.getElementById('root')!).render(
        <Provider store={store}>
            {/* <NotificationContainer /> */}
            <Routes/>
        </Provider>
)
