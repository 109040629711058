import { SET_SESSION } from "../actions/actionTypes";
import { Session } from "../actions/sessionAction";

const initialState: Session = {
    token: null,
    accountId: null,
    email: null,
    userId: null,
    // Any other initial state properties you have
};

export default function sessionReducer(state: Session = initialState, action: any) {
    switch (action.type) {
        case SET_SESSION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
