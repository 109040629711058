export const mccCodes = [
    { code: 742, description: "Veterinary Services" },
    { code: 763, description: "Agricultural Cooperatives" },
    { code: 780, description: "Horticultural Services / Landscaping Services" },
    { code: 1520, description: "General Contractors-Residential and Commercial" },
    { code: 1711, description: "Air Conditioning Contractors - Sales and Installation / Heating Contractors - Sales, Service, Installation" },
    { code: 1731, description: "Electrical Contractors" },
    { code: 1740, description: "Insulation - Contractors / Plastering Contractors / Masonry, Stonework Contractors / Stonework and Masonry Contractors / Tile Settings Contractors" },
    { code: 1750, description: "Carpentry Contractors" },
    { code: 1761, description: "Roofing - Contractors / Sheet Metal Work - Contractors / Siding - Contractors" },
    { code: 1771, description: "Contractors - Concrete Work" },
    { code: 1799, description: "Contractors - Special Trade, Not Elsewhere Classified" },
    { code: 2741, description: "Miscellaneous Publishing and Printing" },
    { code: 2842, description: "Specialty cleaning and sanitation services." },
    { code: 4111, description: "Local/Suburban Commuter Passenger Transportation - Railroads, Ferries, Local Water Transportation" },
    { code: 4119, description: "Ambulance Services" },
    { code: 4121, description: "Taxicabs and Limousines" },
    { code: 4214, description: "Delivery Services - Local / Freight Carriers / Motor Freight Carriers / Moving and Storage Companies / Trucking - Local/Long Distance" },
    { code: 4225, description: "Storage / Warehousing, Public" },
    { code: 4457, description: "Boat Rentals and Leases" },
    { code: 4468, description: "Marinas, Marine Service, and Supplies" },
    { code: 4582, description: "Flying Fields" },
    { code: 4789, description: "Transportation Services, Not Elsewhere Classified" },
    { code: 4812, description: "Telecommunications Equipment including telephone sales" },
    { code: 4899, description: "Cable and other pay television (previously Cable Services)" },
    { code: 4900, description: "Electric, Gas, Sanitary and Water Utilities" },
    { code: 5013, description: "Motor vehicle supplies and new parts" },
    { code: 5021, description: "Office and Commercial Furniture" },
    { code: 5039, description: "Construction Materials, Not Elsewhere Classified" },
    { code: 5044, description: "Office, Photographic, Photocopy, and Microfilm Equipment" },
    { code: 5045, description: "Computers, Computer Peripheral Equipment, Software" },
    { code: 5046, description: "Commercial Equipment, Not Elsewhere Classified" },
    { code: 5047, description: "Medical, Dental Ophthalmic, Hospital Equipment and Supplies" },
    { code: 5065, description: "Electrical Parts and Equipment" },
    { code: 5072, description: "Hardware Equipment and Supplies" },
    { code: 5074, description: "Plumbing and Heating Equipment and Supplies" },
    { code: 5085, description: "Industrial Supplies, Not Elsewhere Classified" },
    { code: 5094, description: "Precious Stones and Metals, Watches and Jewelry" },
    { code: 5099, description: "Durable Goods, Not Elsewhere Classified" },
    { code: 5111, description: "Stationery, Office Supplies, Printing, and Writing Paper" },
    { code: 5122, description: "Drugs, Drug Proprietors and Druggist's Sundries (Card-Present)" },
    { code: 5131, description: "Piece Goods, Notions, and other Dry Goods" },
    { code: 5137, description: "Men's Women's and Children's Uniforms and Commercial Clothing" },
    { code: 5139, description: "Commercial Footwear" },
    { code: 5169, description: "Chemicals and Allied Products, Not Elsewhere Classified" },
    { code: 5172, description: "Petroleum and Petroleum Products" },
    { code: 5192, description: "Books, Periodicals, and Newspapers" },
    { code: 5193, description: "Florists' Supplies, Nursery Stock and Flowers" },
    { code: 5198, description: "Paints, Varnishes, and Supplies" },
    { code: 5199, description: "Non-durable Goods, Not Elsewhere Classified" },
    { code: 5200, description: "Home Supply Warehouse Stores" },
    { code: 5211, description: "Lumber and Building Materials Stores" },
    { code: 5231, description: "Glass Stores / Paint and Wallpaper Stores / Wallpaper Stores" },
    { code: 5251, description: "Hardware Stores" },
    { code: 5261, description: "Nurseries - Lawn and Garden Supply Store" },
    { code: 5300, description: "Wholesale Clubs" },
    { code: 5309, description: "Duty Free Store" },
    { code: 5310, description: "Discount Stores" },
    { code: 5311, description: "Department Stores" },
    { code: 5331, description: "Variety Stores" },
    { code: 5399, description: "Misc. General Merchandise" },
    { code: 5411, description: "Grocery Stores / Supermarkets" },
    { code: 5422, description: "Freezer and Locker Meat Provisioners / Meat Provisioners - Freezer and Locker" },
    { code: 5441, description: "Candy Stores / Confectionary Stores / Nut Stores" },
    { code: 5451, description: "Dairy Products Stores" },
    { code: 5462, description: "Bakeries" },
    { code: 5499, description: "Misc. Food Stores - Convenience Stores and Specialty Markets (EXCLUDING: Personal Enhancement Products and/or Nutraceuticals)" },
    { code: 5511, description: "Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts and Leasing" },
    { code: 5521, description: "Automobile and Truck Dealers (Used Only)" },
    { code: 5532, description: "Automotive Tire Stores" },
    { code: 5533, description: "Automotive Parts, Accessories Stores" },
    { code: 5541, description: "Service Stations (with or without ancillary services)" },
    { code: 5551, description: "Boat Dealers" },
    { code: 5561, description: "Recreational and Utility Trailers, Camp Dealers" },
    { code: 5571, description: "Motorcycle Dealers" },
    { code: 5599, description: "Miscellaneous Auto Dealers" },
    { code: 5611, description: "Men's and Boy's Clothing and Accessories Stores" },
    { code: 5621, description: "Women's Ready-to-Wear Stores" },
    { code: 5631, description: "Women's Accessory and Specialty Shops" },
    { code: 5641, description: "Children's and Infant's Wear Stores" },
    { code: 5651, description: "Family Clothing Stores" },
    { code: 5655, description: "Sports Apparel, Riding Apparel Stores" },
    { code: 5661, description: "Shoe Stores" },
    { code: 5681, description: "Furriers and Fur Shops" },
    { code: 5690, description: "Direct Marketing - Insurance Services" },
    { code: 5691, description: "Men's and Women's Clothing Stores" },
    { code: 5697, description: "Tailors, Seamstress, Mending, and Alterations" },
    { code: 5698, description: "Wig and Toupee Stores" },
    { code: 5699, description: "Miscellaneous Apparel and Accessory Shops" },
    { code: 5712, description: "Furniture, Home Furnishings, and Equipment Stores, Except Appliances" },
    { code: 5713, description: "Floor Covering Stores" },
    { code: 5714, description: "Drapery, Window Covering and Upholstery Stores" },
    { code: 5718, description: "Fireplace, Fireplace Screens, and Accessories Stores" },
    { code: 5719, description: "Miscellaneous Home Furnishing Specialty Stores" },
    { code: 5722, description: "Household Appliance Stores" },
    { code: 5732, description: "Electronic Sales" },
    { code: 5733, description: "Music Stores, Musical Instruments, Piano Sheet Music" },
    { code: 5734, description: "Computer Software Stores" },
    { code: 5735, description: "Record Shops" },
    { code: 5811, description: "Caterers" },
    { code: 5812, description: "Eating places and Restaurants; includes food delivery" },
    { code: 5813, description: "Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques" },
    { code: 5814, description: "Fast Food Restaurants; includes food delivery" },
    { code: 5817, description: "Digital Goods: Applications (Excludes Games)" },
    { code: 5832, description: "Antique Shops - Sales, Repairs, and Restoration Services" },
    { code: 5912, description: "Drug Stores and Pharmacies (Card-Present)" },
    { code: 5921, description: "Package Stores - Beer, Wine, and Liquor (in-person and online)" },
    { code: 5931, description: "Used Merchandise and Secondhand Stores" },
    { code: 5932, description: "Antique Shops" },
    { code: 5940, description: "Bicycle Shops - Sales and Service" },
    { code: 5941, description: "Sporting Goods Stores" },
    { code: 5942, description: "Book Stores" },
    { code: 5943, description: "Stationery Stores, Office and School Supply Stores" },
    { code: 5944, description: "Watch, Clock, Jewelry, and Silverware Stores" },
    { code: 5945, description: "Hobby, Toy, and Game Shops" },
    { code: 5946, description: "Camera and Photographic Supply Stores" },
    { code: 5947, description: "Card Shops, Gift, Novelty, and Souvenir Shops" },
    { code: 5948, description: "Luggage and Leather Goods Stores" },
    { code: 5949, description: "Sewing, Needle, Fabric, and Piece Goods Stores" },
    { code: 5950, description: "Glassware/Crystal Stores" },
    { code: 5964, description: "Direct Marketing - Catalog Merchant" },
    { code: 5970, description: "Artist's Supply and Craft Shops" },
    { code: 5971, description: "Art Dealers and Galleries" },
    { code: 5973, description: "Religious Goods Stores" },
    { code: 5975, description: "Hearing Aids - Sales, Service, and Supply Stores" },
    { code: 5976, description: "Orthopedic Goods Prosthetic Devices" },
    { code: 5977, description: "Cosmetic Stores" },
    { code: 5983, description: "Fuel - Fuel Oil, Wood, Coal, Liquefied Petroleum" },
    { code: 5992, description: "Florists" },
    { code: 5995, description: "Pet Shops, Pet Foods, and Supplies Stores" },
    { code: 5996, description: "Swimming Pools - Sales, Service, and Supplies" },
    { code: 5997, description: "Electric Razor Stores - Sales and Service" },
    { code: 5998, description: "Tent and Awning Shops" },
    { code: 5999, description: "Miscellaneous and Specialty Retail Stores" },
    { code: 6300, description: "Insurance Sales, Underwriting, and Premiums" },
    { code: 6513, description: "Real Estate Agents & Managers" },
    { code: 7011, description: "Lodging - Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)" },
    { code: 7032, description: "Sporting and Recreational Camps (EXCLUDING Nudist Camps)" },
    { code: 7033, description: "Trailer Parks and Camp Grounds" },
    { code: 7210, description: "Laundry, Cleaning, and Garment Services" },
    { code: 7211, description: "Laundries" },
    { code: 7216, description: "Dry Cleaners" },
    { code: 7217, description: "Carpet and Upholstery Cleaning" },
    { code: 7221, description: "Photographic Studios" },
    { code: 7230, description: "Barber and Beauty Shops" },
    { code: 7251, description: "Shoe Repair/Hat Cleaning" },
    { code: 7261, description: "Funeral Service and Crematories" },
    { code: 7276, description: "Tax Preparation Service" },
    { code: 7277, description: "Counseling Service - Marriage, Personal" },
    { code: 7298, description: "Health and Beauty Spas" },
    { code: 7299, description: "Miscellaneous General Services" },
    { code: 7311, description: "Advertising Services" },
    { code: 7332, description: "Blueprinting and Photocopying Services" },
    { code: 7333, description: "Commercial Photography, Art and Graphics" },
    { code: 7338, description: "Quick Copy, Repro, and Blueprint" },
    { code: 7342, description: "Exterminating & Disinfecting Services" },
    { code: 7349, description: "Cleaning and Maintenance, Janitorial Services" },
    { code: 7361, description: "Employment Agencies, Temporary Help Services" },
    { code: 7372, description: "Computer Programming, Integrated Systems Design and Data Processing Services" },
    { code: 7375, description: "Information Retrieval Services" },
    { code: 7379, description: "Computer Maintenance and Repair Services, Not Elsewhere Classified" },
    { code: 7392, description: "Management, Consulting, and Public Relations Services" },
    { code: 7393, description: "Detective Agencies, Protective and Security Services - Including Armored Cars and Guard Dogs" },
    { code: 7394, description: "Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental" },
    { code: 7399, description: "Business Services, Not Elsewhere Classified; Event Planning and Management" },
    { code: 7519, description: "Recreational Vehicle Rentals" },
    { code: 7531, description: "Automotive Body Repair Shops" },
    { code: 7534, description: "Tire Re-treading and Repair Shops" },
    { code: 7538, description: "Automotive Service Shops (Non Dealer)" },
    { code: 7542, description: "Car Washes" },
    { code: 7549, description: "Towing Services" },
    { code: 7622, description: "Electronics Repair Shops" },
    { code: 7623, description: "Air Conditioning and Refrigeration Repair Shops" },
    { code: 7629, description: "Electrical And Small Appliance Repair Shops" },
    { code: 7631, description: "Watch/Jewelry Repair" },
    { code: 7641, description: "Furniture, Furniture Repair, and Furniture Refinishing" },
    { code: 7699, description: "Repair Shops and Related Services - Miscellaneous" },
    { code: 7829, description: "Motion Pictures and Video Tape Production and Distribution" },
    { code: 7832, description: "Motion Picture Theaters" },
    { code: 7911, description: "Dance Halls, Studios and Schools" },
    { code: 7922, description: "Theatrical Producers (Except Motion Pictures), Ticket Agencies" },
    { code: 7941, description: "Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters; Sports arena and Stadium Management" },
    { code: 7991, description: "Tourist Attractions and Exhibits" },
    { code: 7992, description: "Golf Courses - Public" },
    { code: 7993, description: "Video Amusement Game Supplies" },
    { code: 7994, description: "Video Game Arcades/Establishments" },
    { code: 7996, description: "Amusement Parks, Carnivals, Circuses, Fortune Tellers" },
    { code: 7997, description: "Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses" },
    { code: 7999, description: "Recreation Services (Not Elsewhere Classified) (EXCLUDES Shooting Ranges)" },
    { code: 8011, description: "Doctors and Physicians (Not Elsewhere Classified)" },
    { code: 8021, description: "Dentists and Orthodontists" },
    { code: 8031, description: "Osteopaths" },
    { code: 8041, description: "Chiropractors" },
    { code: 8042, description: "Optometrists and Ophthalmologists" },
    { code: 8043, description: "Opticians, Opticians Goods and Eyeglasses" },
    { code: 8049, description: "Podiatrists and Chiropodists" },
    { code: 8050, description: "Nursing and Personal Care Facilities; Home Healthcare" },
    { code: 8062, description: "Hospitals" },
    { code: 8071, description: "Medical and Dental Laboratories" },
    { code: 8099, description: "Medical Services and Health Practitioners (Not Elsewhere Classified)" },
    { code: 8111, description: "Legal Services and Attorneys" },
    { code: 8211, description: "Elementary and Secondary Schools" },
    { code: 8220, description: "Colleges, Junior Colleges, Universities, and Professional Schools" },
    { code: 8241, description: "Correspondence Schools" },
    { code: 8244, description: "Business and Secretarial Schools" },
    { code: 8249, description: "Vocational Schools and Trade Schools" },
    { code: 8299, description: "Schools and Educational Services (Not Elsewhere Classified)" },
    { code: 8351, description: "Child Care Services" },
    { code: 8398, description: "Charitable and Social Service Organizations" },
    { code: 8641, description: "Civic, Fraternal, and Social Associations" },
    { code: 8661, description: "Religious Organizations" },
    { code: 8699, description: "Membership Organizations (Not Elsewhere Classified)" },
    { code: 8734, description: "Testing Laboratories" },
    { code: 8911, description: "Architectural - Engineering and Surveying Services" },
    { code: 8931, description: "Accounting, Auditing, and Bookkeeping Services" },
    { code: 8999, description: "Professional Services (Not Elsewhere Defined)" },
    { code: 9222, description: "Fines" },
    { code: 9311, description: "Tax Payments" },
    { code: 9399, description: "Government Services (Not Elsewhere Classified)" }
  ];
  