// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { config } from '@/config';

const localStorageKey = config.localStorageKey
function saveToLocalStorage(state: any) {
    try {
        // console.log('save the state to permanent storage')
        const serializedState = JSON.stringify(state);
        localStorage.setItem(localStorageKey, serializedState);
    } catch (e) {
        console.error("Could not save state", e);
    }
}
function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem(localStorageKey);
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.error("Could not load state", e);
        return undefined;
    }
}
// let currentState;

// function handleChange() {
//     // Assuming you want to watch changes in a specific part of the state, for example, `somePart`.
//     let nextState = store.getState().somePart;

//     if (nextState !== currentState) {
//         currentState = nextState;
//         // Save the new state to local storage because it has changed
//         saveState({ somePart: currentState });
//     }
// }

const persistedState = loadFromLocalStorage();
console.log('persisted state', persistedState)
const store = configureStore({ reducer: rootReducer, preloadedState: persistedState });
console.log('initialized state', store.getState())

store.subscribe(() => {
    saveToLocalStorage(store.getState());
});
export default store;

