import { useState, useEffect } from "react";
import {Transaction} from '@/types/types'
import StatusBadge from "@/components/StatusBadge";
import { formatCurrency, formatDate } from "@/utils/utilityFunctions";
import { PageHeader } from "@/components/Headers";
import { config } from "@/config";
import { useNavigate } from "react-router-dom";
import { PaginationControls } from "@/components/PaginationComp";
import LoadingModal from "@/components/LoadingModal";
import { TableHeadCell } from "@/components/TableComp";
import useAxios from "@/hooks/useAxios";
import { useSelector } from "react-redux";

export default function Payouts() {
    const axios = useAxios()
    const accountId = useSelector((state: any) => state.session.accountId)
    const [payouts, setPayouts] = useState<Transaction[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate()
    const totalPages = Math.ceil(totalCount / limit)
    useEffect(() => {
        fetchPayouts();
    }, [limit, currentPage]);

    const fetchPayouts = async () => {
        try {
            setLoading(true)
            setError(null);
            const response = await axios.get(`${config.backendUrl}/payments/transactions`, {
                params: {
                    offset: (currentPage - 1) * limit,
                    limit: limit,
                    accountId: accountId,
                    type: 'push'
                },
            });
            console.log(response.data)
            setPayouts(response.data.data);
            setTotalCount(response.data.totalCount)
        } catch (error) {
            console.error("Error fetching payouts:", error);
            setError('Failed to fetch items. Please try again later.');
        } finally {
            setLoading(false)
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    if (error) {
        return <div className="text-red-500 text-center">{error}</div>;
    }
    return (
        <div className="mt-3">
            <PageHeader>Payouts</PageHeader>
            <div className="relative min-h-96 bg-white shadow-md rounded-lg overflow-x-auto">
                <LoadingModal loading={loading} />
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <TableHeadCell>
                            Amount
                            </TableHeadCell>
                            <TableHeadCell>
                            Date
                            </TableHeadCell>
                            <TableHeadCell>
                            Status
                            </TableHeadCell>
                        
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {payouts.map((payout) => (
                            <tr onClick={() => navigate('/payout/' + payout.id)} className="cursor-pointer hover:bg-slate-100" key={payout.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {formatCurrency(payout.amount, payout.currency)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {formatDate(String(payout.createdAt))}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <StatusBadge status={payout.status} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PaginationControls  handlePageChange={handlePageChange} currentPage={currentPage} totalPages={totalPages} />

        </div>
        // </ScrollPage>
    )
}