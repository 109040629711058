import { formatCurrency, formatDate } from '@/utils/utilityFunctions';
import StatusBadge from '@/components/StatusBadge';
import { Table, TableBodyCell, TableHeadCell } from '@/components/TableComp';
import { useNavigate } from 'react-router-dom';
import { CreditCard } from 'lucide-react'
import { Transaction } from '@/types/types';
const PaymentTable = ({ payments }: { payments: Transaction[] }) => {
    console.log(payments)
    const navigate = useNavigate()
    function handleRowClick(payment: Transaction) {
        navigate(`/payments/${payment.id}`)
    }
    return (
        <Table>
            <thead>
                <tr>
                    <TableHeadCell>
                        Amount
                    </ TableHeadCell>
                    <TableHeadCell>
                        Status
                    </ TableHeadCell>
                    <TableHeadCell>
                        Date
                    </ TableHeadCell>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {payments.map && payments?.map((payment) => (
                    <tr className='hover:bg-gray-100 cursor-pointer' key={payment.id} onClick={() => handleRowClick(payment)}>
                        <TableBodyCell className='text-gray-900 font-medium'>
                            {formatCurrency(payment.amount, payment.currency)}
                            
                        </TableBodyCell>
                        <TableBodyCell>
                            <StatusBadge status={payment.status} />
                        </TableBodyCell>
                        <TableBodyCell>
                            {formatDate(payment.createdAt as string)}
                        </TableBodyCell>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default PaymentTable;