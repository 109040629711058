import axios from "axios";
import { useSelector } from "react-redux";
export default function useAxios() {
    const session = useSelector((state: any) => state.session)
    async function get(url: string, config?: any) {
        let cfg: any = config ? config : {}
        cfg.headers = {
            ...cfg.headers,
            Authorization: `Bearer ${session.token}`
        }
        const response = await axios.get(url, cfg);
        return response
    }

    async function post(url: string, data: any, config?: any) {
        let cfg: any = config ? config : {}
        cfg.headers = {
            ...cfg.headers,
            Authorization: `Bearer ${session.token}`
        }
        let response = await axios.post(url, data, cfg);
        return response
    }

    return { get, post };
}