import { Link } from 'react-router-dom';
import { NavItem } from './Navbar';
import NavLink from './NavLink';
import {
    LogOut,} from 'lucide-react';

function DesktopNavBar({navItems: navItems}: {navItems: NavItem[]}) {
    return (
        <nav className="hidden lg:flex flex-col overflow-auto bg-gradient-to-br from-purple-500 to-indigo-400 w-64 text-white h-screen shadow-lg">
            <div className="p-6 flex items-center justify-between">
                <h1 className="text-2xl font-bold text-nowrap">Demo Account</h1>
            </div>
            <ul className="flex-grow space-y-1 px-3 py-4">
                {navItems.map((item) => (
                    <li key={item.name}>
                        <NavLink item={item} />
                    </li>
                ))}
            </ul>
            <div className="p-4">
                <Link
                
                    to="/login"
                    className="flex items-center p-3 hover:bg-indigo-500 rounded-lg transition-colors duration-200"
                >
                    <LogOut className="w-5 h-5 mr-4 text-indigo-200" />
                    <span className="font-medium">Logout</span>
                </Link>
            </div>
            <div className='px-4 mb-10 space-y-2'>
                <div>
                    <Link target='blank' to={'https://docs.synswi.com'} className='text-xs text-gray-100'>
                        Documentation
                    </Link>
                </div>
                <div className='flex justify-between w-3/6'>
                    <Link target='blank' to={'https://www.synswi.com/terms-of-service'} className='text-xs text-gray-100'>
                        Terms
                    </Link>
                    <Link target='blank' to={'https://www.synswi.com/privacy'} className='text-xs text-gray-100'>
                        Privacy
                    </Link>
                </div>
            </div>

        </nav>
    );
};

export default DesktopNavBar;