
import { formatCurrency } from "@/utils/utilityFunctions";
import ScrollPage from "@/components/ScrollPage";
import { PageHeader } from "@/components/Headers";
import Payouts from "./Payouts";
import KPISummary from "./KPISummary";
export default function Balances() {

    return (
        <ScrollPage>
            <PageHeader>Account Overview</PageHeader>
            <KPISummary />
            <Payouts />
        </ScrollPage>
    );
}

interface BalanceCardProps {
    title: string;
    amount: number;
    isTotal?: boolean;
}

function BalanceCard({ title, amount, isTotal = false }: BalanceCardProps) {
    return (
        <div className={`p-4 shadow rounded-lg ${isTotal ? 'bg-blue-50' : 'bg-white'}`}>
            <h3 className="text-sm font-medium text-gray-500 mb-1">{title}</h3>
            <p className={`text-xl font-semibold ${isTotal ? 'text-blue-600' : 'text-gray-900'}`}>
                {formatCurrency(amount, 'usd')}
            </p>
        </div>
    );
}