import { Button } from "./Buttons";


export function RightButton({ handlePageChange, currentPage, totalPages }: {
    handlePageChange: (page: number) => void;
    currentPage: number;
    totalPages: number;
}) {
    return (
        <Button
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}>
            Next
        </Button>
    )
}

export function LeftButton({ handlePageChange, currentPage }: {
    handlePageChange: (page: number) => void;
    currentPage: number;
}) {
    return (
        <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
        >
            Previous
        </Button>

    )
}


export function PaginationControls({ handlePageChange, currentPage, totalPages, className }: {
    handlePageChange: (page: number) => void;
    currentPage: number;
    totalPages: number;
    className?: string;
}) {
    return (
        <div className={"space-x-2 my-5 " + className}>
            <LeftButton
                handlePageChange={handlePageChange}
                currentPage={currentPage}
            />
            <RightButton
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}

            />
        </div>
    )
}

