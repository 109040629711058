import { useState } from "react";
import { ChevronDown, AlignJustify } from 'lucide-react';
import { Link } from "react-router-dom";
import { LogOut } from "lucide-react";
const MobileNavbar = ({ navItems }: { navItems: any }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <header className="lg:hidden w-full p-4 bg-gradient-to-r from-gray-300 to-violet-300">
                <div className="container mx-auto flex justify-between items-center">
                    <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
                        Synswi
                    </h1>
                    <nav onClick={() => setIsOpen(!isOpen)} className="lg:hidden">
                        <AlignJustify />
                    </nav>
                </div >
            </header >
            <div
                className={`fixed lg:hidden inset-0 bg-white z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
            >
                <div
                    className={`absolute inset-y-0  w-full transform transition-transform duration-300 ease-in-out 
                        ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
                >
                    <div className="flex flex-col h-full">
                        <div className="flex justify-between items-center p-4 border-b border-gray-200">
                            <h2 className="text-xl font-bold text-blue-400">Synswi</h2>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="text-gray-400 hover:text-white"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <nav className="flex-grow overflow-y-auto">
                            <ul className="p-4 space-y-2">
                                {navItems.map((item: any) =>
                                    item.children ?
                                        <li key={item.name}>
                                            <CompositeMobileMenuItem item={item} />
                                        </li>
                                        :
                                        <MobileMenuItem close={() => setIsOpen(false)} key={item.name} blank={item.blank} href={item.href ? item.href : ''} icon={item.icon} name={item.name} />
                                )}
                            </ul>
                        </nav>
                        <div className="p-4">
                            <Link
                                to="/login"
                                className="flex items-center p-3 hover:bg-indigo-500 rounded-lg transition-colors duration-200"
                            >
                                <LogOut className="w-5 h-5 mr-4 text-indigo-600" />
                                <span>Logout</span>
                            </Link>
                        </div>
                        <div className='px-4 mb-10 space-y-2'>
                            <div>
                                <Link target='blank' to={'https://docs.synswi.com'} className='text-xs text-gray-500'>
                                    Documentation
                                </Link>
                            </div>
                            <div className='flex justify-between w-3/6'>
                                <Link target='blank' to={'https://www.synswi.com/terms-of-service'} className='text-xs text-gray-500'>
                                    Terms
                                </Link>
                                <Link target='blank' to={'https://www.synswi.com/privacy'} className='text-xs text-gray-500'>
                                    Privacy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function CompositeMobileMenuItem({ item }: { item: any }) {
    const [openMobileProducts, setOpenMobileProducts] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpenMobileProducts(!openMobileProducts)}
                className="w-full flex items-center justify-between p-3 rounded-lg text-gray-300 hover:bg-gray-800 hover:text-white transition duration-200"
            >
                <span className="flex items-center">
                    <ChevronDown size={20} className={`mr-3 transform transition-transform duration-200 ${openMobileProducts ? 'rotate-180' : ''}`} />
                    {item.name}
                </span>
            </button>
            {
                openMobileProducts && (
                    <ul className="mt-2 ml-6 space-y-2">
                        {item.children?.map((subItem: any) =>
                            <MobileMenuItem key={subItem.name} href={subItem.href} icon={subItem.icon} name={subItem.name} />

                        )}
                    </ul>
                )
            }
        </>
    )
}

const MobileMenuItem = ({ href, icon: Icon, name, blank, close }: any) => (
    <li>
        {blank ?
            <Link target="_blank" to={href!} onClick={close} className="flex items-center p-3 rounded-lg text-gray-300 hover:bg-gray-400 hover:text-white transition duration-200">
                {Icon && <Icon size={18} />}

                <span className="ml-3">{name}</span>
            </Link>
            :
            <Link to={href!} onClick={close} className="flex items-center p-3 rounded-lg text-gray-500 hover:bg-gray-400 hover:text-white transition duration-200">
                {Icon && <Icon size={18} />}
                <span className="ml-3">{name}</span>
            </Link>
        }
    </li>
);

export default MobileNavbar;