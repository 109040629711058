import React from 'react';
import { ArrowUpRight, ArrowDownRight, DollarSign } from 'lucide-react';

// Mock data - replace with actual API calls in a real application
const mockKPIData = {
    totalRevenue: 15000,
    transactionVolume: 500,
    averageTransactionValue: 30,
    conversionRate: 3.5
};

function Card({ props }: { props: { name: string; icon: any; text: string } }) {
    return (
        <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <props.icon className="h-6 w-6 text-gray-400" />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                        <dl>
                            <dt className="text-sm font-medium text-gray-500 truncate">{props.name}</dt>
                            <dd>
                                <div className="text-lg font-medium text-gray-900">{props.text}</div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

const KPISummary: React.FC = () => {
    return (
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <Card props={{ name: 'Today\'s Total Revenue', text: `$${mockKPIData.totalRevenue.toFixed(2)}`, icon: DollarSign }} />
            <Card props={{ name: 'Today\'s Transaction Count', text: `${mockKPIData.transactionVolume}`, icon: ArrowUpRight }} />
           </div>
    );
};

export default KPISummary;