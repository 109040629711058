export default function StatusBadge({status}: {status: string}) {
    function className(status: string) {
        if (status === 'completed' || status === 'succeeded') {
            return 'bg-green-100 text-green-800'
        } else if (status === 'pending') {
            return 'bg-yellow-100 text-yellow-800'
        } else if (status === 'failed') {
            return 'bg-red-100 text-red-800'
        }
    }   
    return (
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            className(status)
          }`}>
            {status}
          </span>
    )
} 