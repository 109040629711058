import { PageHeader } from "@/components/Headers"
import { TextInput } from "@/components/Inputs"
import { useState } from "react"
import useAxios from "@/hooks/useAxios"
import { config } from "@/config"
import PageButtonGroup from "./ProfileFormPageButtonGroup"
import { useSelector } from "react-redux"

export default function TransactionDetails({ transactionDetails, setStep, setProfile }:
    { transactionDetails: any, setStep: any, setProfile: any }) {
    const accountId = useSelector((state: any) => state.session.accountId)
    const axios = useAxios()
    let t = {
        averageMonthlyVolume: '',
        maximumTransactionAmount: '',
        averageTransactionAmount: '',
        ...transactionDetails
    }
    const [formData, setFormData] = useState(t)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let oldTransactionDetails = formData
        let newTransactionDetails = { ...oldTransactionDetails, [name]: value }
        setFormData(newTransactionDetails)
    };
    const handleNext = async () => {
        await axios.post(`${config.backendUrl}/payments/profiles`, { accountId, transactionDetails: formData })
        setProfile((prev: any) => { return { ...prev, transactionDetails: formData } })
        setStep((prev: number) => prev + 1)
    }

    return (
        <div>
            <PageHeader>
                Transaction Details
            </PageHeader>
            <div className="mb-4">
                <TextInput label="Average Monthly Volume" name="averageMonthlyVolume" value={formData.averageMonthlyVolume} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Maximum Transaction Amount" name="maximumTransactionAmount" value={formData.maximumTransactionAmount} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Average Transaction Amount" name="averageTransactionAmount" value={formData.averageTransactionAmount} onChange={handleInputChange} />
            </div>
            <PageButtonGroup handleNext={handleNext} handlePrev={() => setStep((prev: number) => prev - 1)} />
        </div>
    )
}