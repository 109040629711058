import { createBrowserRouter, Navigate, RouterProvider, } from "react-router-dom";
import PaymentsPage from '@/pages/payments/Payments.tsx'
import Layout from '@/layouts/Layout.tsx'
import PaymentDetail from "./pages/payments/PaymentDetail";
import Balances from "./pages/balances/BalancesPage";
import InvoicePage from "./pages/invoices/Invoices";
import CreateInvoicePage from "./pages/invoices/CreateInvoicePage";
import ApiKeys from "./pages/apiKeys/ApiKeys";
import Settings from "./pages/settings/Settings";
import Disputes from "./pages/disputes/Disputes";
import PayoutDetail from "./pages/balances/PayoutDetail";
import Payouts from "./pages/balances/Payouts";
import LogInPage from "./pages/login/Login";
import PrivateRoute from '@/components/PrivateRoute'
import ProfileEditPage from "./pages/profile/ProfileEditPage";
import ProfilePage from "./pages/profile/ProfilePage";
import VirtualTerminal from "./pages/virtualTerminal/VIrtualTerminal";
export const router = createBrowserRouter([
    {
        path: '/login',
        element: <LogInPage />
    },
    {
        path: '/',
        element: <PrivateRoute />,
        children: [
            {
                path: '',
                element: <Layout />,
                children: [
                    { path: "", element: <Navigate to="balances" replace /> },
                    {
                        path: 'payments',
                        element: <PaymentsPage />
                    },
                    {
                        path: 'payments/:id',
                        element: <PaymentDetail />
                    },
                    {
                        path: 'balances',
                        element: <Balances />
                    },
                    {path: 'virtual-terminal', element: <VirtualTerminal />},
                    // {
                    //     path: 'invoices',
                    //     element: <InvoicePage />
                    // },
                    // {
                    //     path: 'create_invoice',
                    //     element: <CreateInvoicePage />
                    // },
                    // {
                    //     path: 'api-keys',
                    //     element: <ApiKeys />
                    // },
                    // {
                    //     path: 'settings',
                    //     element: <Settings />
                    // },
                    {
                        path: 'disputes',
                        element: <Disputes />
                    },
                    {
                        path: 'payout/:id',
                        element: <PayoutDetail />
                    },
                    {
                        path: 'payouts', element: <Payouts />
                    },
                    { path: 'profile/edit', element: <ProfileEditPage /> },
                    { path: 'profile', element: <ProfilePage /> }

                ]
            }
        ]
    },

]);

function Routes() {
    return (
        <RouterProvider router={router} />
    );
}

export default Routes;
