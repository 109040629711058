import { SET_SESSION } from "./actionTypes";

export type Session = {
    token?: string | null;
    accountId?: string | null,
    email?: string | null,
    userId?: string | null,
}

export function setSession(session: Session) {
    return {
        type: SET_SESSION,
        payload: session
    }
}