import DesktopNavBar from "./DesktopNavBar"
import MobileNavBar from "./MobileNavbar"
import {
    DollarSign,
    BadgeDollarSign,
    KeyRound,
    Settings,
    Wallet,
    Hammer,
    SquareTerminal,
    User
} from 'lucide-react';
export interface NavItem {
    name: string;
    href: string;
    icon: React.ElementType;
}
const navItems: NavItem[] = [
    { name: 'Balances', href: '/balances', icon: Wallet },
    { name: 'Payments', href: '/payments', icon: DollarSign },
    {name: 'Virtual Terminal', href: '/virtual-terminal', icon: SquareTerminal},
    // { name: 'Invoices', href: '/Invoices', icon: BadgeDollarSign },
    { name: 'Disputes', href: '/disputes', icon: Hammer },
    // { name: 'Api Keys', href: '/api_keys', icon: KeyRound },
    // { name: 'Settings', href: '/settings', icon: Settings },
    { name: 'Profile', href: '/profile', icon: User },
];
export default function Navbar() {
    return (
        <>
            <DesktopNavBar navItems={navItems} />
            <MobileNavBar navItems={navItems} />
        </>
    )
}