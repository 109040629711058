export function TextInput({ label, icon, placeholder, value, name, onChange, className, disabled }: any) {
    return (
        <div className={`mb-4 ${className}`}>
            {label &&
                <label className="block text-sm font-medium mb-1">{label}</label>
            }
            <div className={`flex rounded items-center border border-gray-200 py-2`}>
                {icon}
                <input
                    type={'text'}
                    name={name}
                    className={`appearance-none bg-transparent border-none w-full text-gray-700 py-0.5 px-2 
                    leading-tight focus:outline-none
                    
                    `}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export function Selectinput({ label, onChange, className, name, value, options }: {options: any; value: string; name: string, label: string, onChange: any, className?: string }) {
    return (
        <div className={`mb-4 ${className}`}>
            {label &&
                <label className="block text-sm font-medium mb-1">{label}</label>
            }
            <select
                name={name}
                value={value}
                onChange={onChange}
                className="border rounded w-full py-2 px-3"
            >
                {options.map((option: any) => 
                    <option key={option.value} value={option.value}>{option.label}</option>
                )}
            </select>
        </div>
    )
}