export function Button({ onClick, disabled, className, children }: any) {
    return (
        <button onClick={onClick}
            className={`py-1 px-2 rounded-lg shadow-md transition-all duration-300 ease-in-out text-sm ${className}
        ${disabled
                    ? 'bg-gray-200 cursor-not-allowed'
                    : 'bg-gradient-to-r from-blue-600 to-blue-500 text-white hover:from-blue-700 hover:to-blue-600 active:shadow-inner'
                }
            
        `}
            disabled={disabled}>
            {children}
        </button>
    )
}