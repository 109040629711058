import ScrollPage from '@/components/ScrollPage';
import React, { useEffect, useState } from 'react';
import BusinessDetail from './BusinessDetails';
import TransactionDetails from './TransactionDetails';
import useAxios from '@/hooks/useAxios';
import { config } from '@/config';
import { useSelector } from 'react-redux';
import Representatives from './Representatives';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import LoadingModal from '@/components/LoadingModal';
const ProfileEditPage: React.FC = () => {
    const axios = useAxios()
    const accountId = useSelector((state: any) => state.session.accountId)
    const [profile, setProfile] = useState<any>(null)
    const [step, setStep] = useState(1);

    useEffect(() => {
        const fetchProfile = async () => {
            const option = {
                params: {
                    accountId
                }
            }
            const response = await axios.get(`${config.backendUrl}/payments/profiles`, option)
            setProfile(response.data)
        }
        fetchProfile()
    }, [])

    return (
        <ScrollPage>
            <LoadingModal loading={profile === null} />
            {profile &&
                <div>
                    <Link to="/profile" className="mb-6 inline-flex items-center px-4 py-2 text-sm font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-700 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-300 focus:ring-offset-2">
                        <ArrowLeft className="w-5 h-5 mr-3" />
                        Back to Profile
                    </Link>
                    <div className='flex flex-col-reverse md:grid md:grid-cols-5 md:gap-10'>
                        <div className='md:col-span-3'>
                            {step === 1 && (
                                <BusinessDetail businessDetails={profile.businessDetails} setStep={setStep} setProfile={setProfile} />
                            )}
                            {step === 2 && (
                                <TransactionDetails
                                    transactionDetails={profile.transactionDetails}
                                    setStep={setStep}
                                    setProfile={setProfile}
                                />
                            )}
                            {step === 3 && (
                                <Representatives representatives={profile.representatives} setStep={setStep} setProfile={setProfile} />
                            )}
                        </div>
                        <div className="md:col-span-2 text-sm md:pl-10 md:pt-10">
                            <div className="flex flex-col space-y-3 justify-between mb-4">
                                <div onClick={() => setStep(1)} className={`cursor-pointer flex items-center ${step === 1 ? 'text-blue-600' : 'text-gray-700'}`}>
                                    <ArrowRight className="w-4 h-4 mr-2" />
                                    Business details
                                </div>
                                <div onClick={() => setStep(2)} className={`cursor-pointer flex items-center ${step === 2 ? 'text-blue-600' : 'text-gray-700'}`}>
                                    <ArrowRight className="w-4 h-4 mr-2" />
                                    Transaction details
                                </div>
                                <div onClick={() => setStep(3)} className={`cursor-pointer flex items-center ${step === 3 ? 'text-blue-600' : 'text-gray-700'}`}>
                                    <ArrowRight className="w-4 h-4 mr-2" />
                                    Representatives
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </ScrollPage>
    );
};

export default ProfileEditPage;
