import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";
import { config } from "../config";
import { useNavigate } from "react-router-dom";
import LoadingModal from "./LoadingModal";
export default function PrivateRoute() {
    const navigate = useNavigate()
    const [sessionStatus, setSessionStatus] = useState('unknown')
    const { token } = useSelector((state: any) => state.session)

    useEffect(() => {
        async function verifyToken() {
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
            try {
                let response = await axios.post(`${config.backendUrl}/payments/session`, null, axiosConfig)
                // todo: need to save the latest session data
                setSessionStatus('valid')
            } catch (err) {
                console.log(err)
                console.log('session invalid')
                navigate('/login')
            }
        }
        verifyToken()
    }, [])
    if (!token || sessionStatus === 'invalid') {
        return <Navigate to={'/login'} />
    }
    if (token && sessionStatus === 'unknown') {
        return (
            <div className="h-full">
                <LoadingModal loading />
            </div>
        )
    }
    return <Outlet />
}