import ScrollPage from "@/components/ScrollPage";
import { PageHeader } from "@/components/Headers";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { config } from "@/config";
import { formatCurrency } from "@/utils/utilityFunctions";
import StatusBadge from "@/components/StatusBadge";
import { formatDate } from "@/utils/utilityFunctions";
import useAxios from "@/hooks/useAxios";
import { Transaction } from "@/types/types";
export default function PayoutDetail() {
    const axios = useAxios()
    const { id } = useParams()
    const [payout, setPayout] = useState<Transaction | null>(null)
    useEffect(() => {
        async function fetch() {
            const { data } = await axios.get(`${config.backendUrl}/payments/transactions/${id}`)
            setPayout(data)
            console.log(data)
        }
        fetch()
    }, [])
    if (!payout) {
        return <div>
        </div>
    }
    return (
        <ScrollPage>
            <PageHeader>
                Payout Detail
            </PageHeader>
            <div className=" space-y-4">
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">payout ID</span>
                    <span className="text-sm text-gray-900">{payout.id}</span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Amount</span>
                    <span className="text-lg font-semibold text-gray-900">{formatCurrency(payout.amount, payout.currency)}</span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Currency</span>
                    <span className="text-sm flex items-center text-gray-900">
                         {payout.currency!}
                    </span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Status</span>
                    {payout.status}
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Account ID</span>
                    <span className="text-sm text-gray-900">{payout.accountId}</span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Payout Method</span>
                    <span className="text-sm flex items-center text-gray-900">
                         {payout.method!}
                    </span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Created At</span>
                    <span className="text-sm text-gray-900">{formatDate(payout.createdAt as string)}</span>
                </FieldContainer>
            </div>
        </ScrollPage>
    )
}

function FieldContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            {children}
        </div>
    )
}