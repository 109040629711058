import { TextInput } from "@/components/Inputs";
import { PageHeader } from "@/components/Headers";
import { useState } from "react";
import useAxios from "@/hooks/useAxios";
import { config } from "@/config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Buttons";
export default function Representatives({ representatives, setStep, setProfile }:
    { representatives: any, setStep: any, setProfile: any }) {
    const navigate = useNavigate()
    const axios = useAxios()
    const accountId = useSelector((state: any) => state.session.accountId)
    let t = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dob: '',
        ssn: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: 'united states',
        zipcode: '',
        ...(representatives?.[0] ?? {})
    }
    const [formData, setFormData] = useState(t)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let representative = formData
        let newRepresentatives = { ...representative, [name]: value }
        setFormData(newRepresentatives)
    };

    const handleNext = async () => {
        await axios.post(`${config.backendUrl}/payments/profiles`, { accountId, representatives: [formData] })
        setProfile((prev: any) => { return { ...prev, representatives: [formData] } })
        navigate(-1)
    }

    return (
        <div>
            <PageHeader>Representative details</PageHeader>
            <div className="mb-4">
                <TextInput label="Legal First Name" name="firstName" value={formData.firstName} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Legal Last Name" name="lastName" value={formData.lastName} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Email" name="email" value={formData.email} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Phone Number" name="phone" value={formData.phone} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label={"Date Of Bith"} name="dob" value={formData.dob} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Social Security Number" name="ssn" value={formData.ssn} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Personal Address" name="addressLine1" value={formData.addressLine1} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="City" name="city" value={formData.city} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="State" name="state" value={formData.state} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput disabled label="Country" name="country" value={formData.country} onChange={handleInputChange} />
            </div>
            <div className="mb-4">
                <TextInput label="Zip Code" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
            </div>
            <div className="space-x-3">
                <Button onClick={() => setStep((prev: number) => prev - 1)}>Previous</Button>
                <Button onClick={handleNext}>Finish</Button>

            </div>
        </div>
    )
}