

interface Props {children: React.ReactNode}

export function TableBodyCell({ children, className }: { children: React.ReactNode, className?: string }) {
    return (
        <td className={"px-6 py-4 whitespace-nowrap text-sm text-gray-500 " + className}>
            {children}
        </td>
    )
}

export function Table({ children }: Props) {
    return (
        <div className="bg-white shadow-md rounded-lg overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
                {children}
            </table>
        </div>
    )
}

export function TableHeadCell({ children }: Props) {
    return (
        <th className={" px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>
            {children}
        </th>
    )
}