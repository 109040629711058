import React, { useEffect, useState } from 'react';
import { Plus } from 'lucide-react';
import PaymentTable from './PaymentsTable';
import ScrollPage from '@/components/ScrollPage';
import { PaginationControls } from '@/components/PaginationComp';
import LoadingModal from '@/components/LoadingModal';
import { config } from '@/config';
import useAxios from '@/hooks/useAxios';
import { Transaction } from '@/types/types';
import { useSelector } from 'react-redux';
import { PageHeader } from '@/components/Headers';
const PaymentsPage: React.FC = () => {
    const axios = useAxios()
    const accountId = useSelector((state: any) => state.session.accountId)
    const [payments, setPayments] = useState<Transaction[]>([])
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [error, setError] = useState<string | null>(null);
    const totalPages = Math.ceil(totalCount / limit)

    console.log('payment page', { payments })
    useEffect(() => {
        fetchData()
    }, [currentPage])

    const fetchData = async () => {
        try {
            setLoading(true)
            setError(null);
            let option = {
                params: {
                    offset: (currentPage - 1) * limit,
                    limit: limit,
                    accountId: accountId,
                    type: 'pull'
                },
            }
            const response = await axios.get(`${config.backendUrl}/payments/transactions`, option);
            console.log(response.data)
            setPayments(response.data.data);
            setTotalCount(response.data.totalCount)
        } catch (error) {
            console.error("Error fetching payouts:", error);
            setError('Failed to fetch items. Please try again later.');
        } finally {
            setLoading(false)
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <ScrollPage>
            <header className="bg-white">
                <div className="mx-auto flex justify-between items-center">
                    <PageHeader>Payments</PageHeader>
                    {/* <div className="flex items-center space-x-4">
                        <button className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <Plus size={16} className="inline mr-2" />
                            Create payment
                        </button>
                    </div> */}
                </div>
            </header>
            <div className='relative min-h-96'>
                <LoadingModal loading={loading} />
                <PaymentTable payments={payments} />
            </div>
            <PaginationControls handlePageChange={handlePageChange} currentPage={currentPage} totalPages={totalPages} />

        </ScrollPage>
    );
};

export default PaymentsPage;