import { Link } from "react-router-dom"
import { NavItem } from "./Navbar"
export default function NavLink({item}: {item: NavItem}) {
    let Icon: React.ElementType = item.icon
    return (
        <Link
            to={item.href}
            className="flex items-center p-3 rounded-lg hover:bg-indigo-500 transition-colors duration-200"
        >
            <span className="mr-4 text-indigo-200"><Icon className = "w-5 h-7 text-blue-300" /></span>
            <span className="font-medium">{item.name}</span>
        </Link>
    )
}