import { useEffect, } from "react";
import useAxios from "@/hooks/useAxios";
import { formatCurrency, formatDate } from "@/utils/utilityFunctions";
import StatusBadge from "@/components/StatusBadge";
import ScrollPage from "@/components/ScrollPage";
import { PageHeader } from "@/components/Headers";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Transaction } from "@/types/types";
import { config } from "@/config";
const PaymentDetail = () => {
    const axios = useAxios()
    const { id } = useParams()
    const [payment, setPayment] = useState<Transaction | null>(null)
    useEffect(() => {
        async function fetch() {
            const { data } = await axios.get(`${config.backendUrl}/payments/transactions/${id}`)
            setPayment(data)
            console.log(data)
        }
        fetch()
    }, [])
    if (!payment) {
        return <div>
        </div>
    }
    return (
        <ScrollPage>
            <PageHeader>Payment Details</PageHeader>
            <div className="space-y-4">
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Account ID</span>
                    <span className="text-sm text-gray-900">{payment.accountId}</span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Payment ID</span>
                    <span className="text-sm text-gray-900">{payment.id}</span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Amount</span>
                    <span className="text-lg font-semibold text-gray-900">{formatCurrency(payment.amount, payment.currency)}</span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Currency</span>
                    <span className="text-sm flex items-center text-gray-900">
                        {payment.currency!}
                    </span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Channel</span>
                    <span className="text-sm flex items-center text-gray-900">
                        {payment.channel!}
                    </span>
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Status</span>
                    <StatusBadge status={payment.status} />
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Payment Method</span>
                    <span className="text-sm flex items-center text-gray-900">{payment.method!}</span>
                    
                </FieldContainer>
                <FieldContainer>
                    <span className="text-sm font-medium text-gray-500">Created At</span>
                    <span className="text-sm text-gray-900">{formatDate(payment.createdAt as string)}</span>
                </FieldContainer>
            </div>
        </ScrollPage>
    );
};

function FieldContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            {children}
        </div>
    )
}

export default PaymentDetail;